<template>
  <a-spin :spinning="spinning" class="max-width" style="padding-bottom: 60px">
    <a-row type="flex" style="margin: 20px auto; max-width: 1600px;">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <router-link :to="'/'">
            <a-icon type="home" />首页
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <router-link :to="'cart'">购物车</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>确认订单</a-breadcrumb-item>
      </a-breadcrumb>
    </a-row>
    <!-- 产品列表 -->
    <a-divider class="theme" orientation="center">订单产品列表</a-divider>
    <div v-for="supplier in data" :key="supplier[0].id_order_cart">
      <a-table
        :columns="[
        { title: '产品', dataIndex: 'url', scopedSlots: { customRender: 'url' }, width: 110 },
        { title: '名称', dataIndex: 'name', scopedSlots: { customRender: 'name' }, width: 250 },
        { title: '数量', dataIndex: 'amount' },
        { title: '单位', dataIndex: 'product_unit' },
        { title: '小计', dataIndex: 'price_settle', customRender: (text, record) => (record.amount * record.price_settle).toFixed(2) },
        { title: '订单备注', dataIndex: 'note', scopedSlots: { customRender: 'note' }, width: 200 }
      ]"
        size="small"
        :row-key="record => record.id_order_cart"
        :data-source="supplier"
        :pagination="false"
      >
        <!-- 图片 -->
        <span slot="url" slot-scope="text">
          <div class="image">
            <img mode="aspectFix" v-lazy="text" />
          </div>
        </span>
        <!-- 名称 -->
        <span slot="name" slot-scope="text, record">
          <router-link :to="{ name: 'product', query: { id: record.id_product_info }}">
            <a class="theme" style="text-decoration: underline;">{{ text }}</a>
          </router-link>
          <br />
          <span>￥{{ record.price_settle }}</span>
        </span>
        <!-- 备注 -->
        <span slot="note" slot-scope="text, record">
          {{ text }}
          <a
            @click="record.newNote = record.note || ''; modalData = record; isShowNoteModal = true"
          >
            <a-icon type="edit" />给供应商留言
          </a>
        </span>
        <template slot="footer">运费描述：{{ supplier[0].freight_note || '暂无运费描述' }}</template>
      </a-table>
      <br />
    </div>
    <!-- 产品合计 -->
    <div class="buy">
      <span class="grey" style="line-height: 50px">
        共
        <span class="red">{{ dataKey.length }}</span>
        件产品
      </span>
      <a-row type="flex">
        <div class="red" style="line-height: 50px;">
          合计 ￥
          <span style="font-size:27px" class="bold">{{ totalPrice.toFixed(2) }}</span>
        </div>
      </a-row>
    </div>
    <!-- 收货信息 -->
    <a-divider class="theme" orientation="center">收货信息</a-divider>
    <a-descriptions :column="1" size="small" bordered>
      <a-descriptions-item label="门店名称">{{ userInfo.hotel_name }}</a-descriptions-item>
      <a-descriptions-item label="收货人及电话">
        {{ userInfo.contact }}
        <a
          class="yellow"
          @click="userInfo.newContact = userInfo.contact || ''; modalData = userInfo; isShowContactModal = true"
        >
          <a-icon type="edit" />修改
        </a>
      </a-descriptions-item>
      <a-descriptions-item label="收货地址">{{ userInfo.shipping_info }}</a-descriptions-item>
    </a-descriptions>
    <!-- 收货信息 -->
    <a-divider class="theme" orientation="center">确认开票信息</a-divider>
    <a-alert v-show="invoiceType === null" message="请选择本次订单所需的发票类型" type="error" />
    <div style="text-align: center">
      <a-button
        style="margin: 20px"
        v-for="i in invoiceInfo"
        :key="i.id"
        :type="invoiceType == i.id ? 'primary' : ''"
        @click="invoiceType = i.id"
      >{{ i.title }}</a-button>
    </div>
    <a-descriptions :column="2" size="small" bordered>
      <a-descriptions-item label="发票抬头">{{ userInfo.company_name }}</a-descriptions-item>
      <a-descriptions-item label="发票接收邮箱">{{ userInfo.email }}</a-descriptions-item>
      <a-descriptions-item label="纳税人识别号">{{ userInfo.company_code }}</a-descriptions-item>
      <a-descriptions-item label="公司注册地址">{{ userInfo.company_address }}</a-descriptions-item>
      <!-- <a-descriptions-item label="发票种类">增值税专用发票</a-descriptions-item> -->
      <a-descriptions-item label="银行账户名称">{{ userInfo.bank_name }}</a-descriptions-item>
      <a-descriptions-item label="开户银行及账号">{{ userInfo.bank_number }}</a-descriptions-item>
    </a-descriptions>
    <!-- 支付方式 -->
    <a-divider class="theme" orientation="center">支付方式</a-divider>
    <div style="text-align: center">
      <a-button type="primary">对公转账（默认）</a-button>
    </div>
    <!-- 注意事项 -->
    <a-divider class="theme" orientation="center">注意事项</a-divider>
    <ol>
      <li
        :key="item"
        v-for="item in [
          '本下单账号仅供账号使用人自己使用，如非本账号使用人使用，则本人承诺已经获得了账号使用人充分的授权。',
          '本告知书提交后，相对应的订单也将一并提交，订单提交后，则视为本人已发出一个有效的订单承诺，在供应商确认后，则视为《订单合同》生效。本人已经知悉并理解《订单合同》及告知书的全部内容，并同意接受其约束。',
          '本人确认，舒悦商城已就限制本人权利或舒悦商城单方拥有某些权利的所有条款向本人作出相应提示和说明，本人对所有条款完全理解并自愿接受该内容。',
        ]"
      >
        <p>{{ item }}</p>
      </li>
    </ol>
    <!-- 确认按钮 -->
    <div style="text-align: center">
      <div style="margin: 20px">
        <a-checkbox @change="e => isConfirmed = e.target.checked">我已阅读并同意以上注意事项</a-checkbox>
      </div>
      <a-button
        type="primary"
        size="large"
        style="padding: 0 30px"
        @click="buy"
        :loading="spinning"
        :disabled="!isConfirmed"
      >确 认 提 交 订 单</a-button>
    </div>
    <!-- 弹框：订单备注 -->
    <a-modal
      v-model="isShowNoteModal"
      :okButtonProps="{ props: { loading: isLoadNoteModal } }"
      :maskClosable="false"
      :closable="false"
      okText="保存"
      title="订单备注"
      @ok="onModifyNote"
    >
      <a-textarea
        :disabled="isLoadNoteModal"
        v-model="modalData.newNote"
        placeholder="请输入本单备注并保存"
        :maxLength="250"
        style="height: 200px"
      />
    </a-modal>
    <!-- 弹框：收货人 -->
    <a-modal
      v-model="isShowContactModal"
      :okButtonProps="{ props: { loading: isLoadContactModal } }"
      :maskClosable="false"
      :closable="false"
      okText="保存"
      title="修改收货人"
      @ok="onModifyContact"
    >
      <a-input
        :disabled="isLoadNoteModal"
        v-model="modalData.newContact"
        placeholder="请输入收货人姓名并保存"
        :maxLength="250"
      />
    </a-modal>
  </a-spin>
</template>
<script>
export default {
  data() {
    return {
      invoiceInfo: [
        { title: '本次不开发票', id: 1 },
        { title: '(纸质) 增值税专用发票', id: 2 },
        { title: '(电子) 增值税普通发票', id: 3 },
        { title: '(纸质) 增值税普通发票', id: 4 }
      ],
      data: [], // 选中的产品
      dataKey: [], // 选中的产品id
      totalPrice: 0, // 总价
      userInfo: {}, // 收货信息
      invoiceType: null, // 发票类型
      isConfirmed: false, // 是否打钩
      isShowNoteModal: false, // 显示订单备注修改弹框
      isLoadNoteModal: false,
      isShowContactModal: false, // 显示收货人修改弹框
      isLoadContactModal: false,
      modalData: {},
      spinning: true
    }
  },

  mounted() {
    this.dataKey = this.getSession('orderKey')
    this.get('getSupplierCartForFranchisee', {
      id_order_cart: this.dataKey
    })
      .then((res) => {
        this.data = res
        this.spinning = false
        this.totalPrice = this.getSession('orderMoney')
      })
      .catch((err) => {
        this.$message.error(err.msg)
        this.$router.replace('/cart')
      })
    this.totalPrice = this.data
      .map((record) => parseFloat(record.order_money))
      .reduce((prev, cur) => prev + cur, 0) // 求和
    this.userInfo = this.getUser()
  },

  methods: {
    onModifyNote() {
      this.isLoadNoteModal = true
      this.post('modifyCartForFranchisee', {
        id_order_cart: this.modalData.id_order_cart,
        note: this.modalData.newNote || ''
      })
        .then(() => {
          this.$message.success('修改备注成功')
          this.modalData.note = this.modalData.newNote
          this.isShowNoteModal = false
          this.isLoadNoteModal = false
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.isLoadNoteModal = false
        })
    },
    onModifyContact() {
      this.isLoadContactModal = true
      this.post('modifyFranchiseeForFranchisee ', {
        contact: this.modalData.newContact
      })
        .then((res) => {
          this.$message.success('修改收货人名称成功')
          this.setSession('user', res)
          this.modalData.contact = this.modalData.newContact
          this.isShowContactModal = false
          this.isLoadContactModal = false
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.isLoadContactModal = false
        })
    },
    buy() {
      if (this.invoiceType == null) {
        return this.$message.error('请先选择发票类型')
      }
      this.spinning = true
      this.post('addMasterForFranchisee', {
        id_order_cart: this.dataKey,
        payment_method: 0,
        invoice_type: this.invoiceType
      }).then(() => {
        this.isConfirmed = false
        this.$message.success('订单已提交')
        this.$router.replace({
          name: 'orders',
          params: { tab: 2, unpay: 1 }
        })
      })
    }
  }
}
</script>

<style scoped>
.max-width {
  /* margin: 20px auto; */
  padding: 0 50px;
  max-width: 1600px;
  min-width: 900px;
}
.image {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image img {
  max-width: 100%;
  max-height: 70px;
}
.buy {
  padding: 0 20px;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.theme {
  margin-top: 50px;
}
</style>
